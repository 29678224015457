import React, {useState, useRef, useEffect, useContext} from "react";
import { Link } from "gatsby"
import Image from './image'
import { gsap } from 'gsap'
import { SlowMo } from 'gsap/EasePack'
// import MobNav from './mobilenav'
import SignUp from "../components/signup";
import SignIn from "../components/signin";
import { AuthContext } from '../firebase'
import { FirebaseContext } from '../firebase'

gsap.registerPlugin(SlowMo)


const Nav = (props) => {
  const userInit = {myUser: ""}
  const { authUser, setAuthUser }  = useContext(AuthContext) || userInit
  const { user } = authUser || userInit
  const Firebase = useContext(FirebaseContext)

  const logoutUser = async () =>{
    await Firebase.logout()
    setAuthUser({user: ""})
  }
  const [signin, setSignin] = useState(false)

  const showSignin = () => {
    setSignin(true)
  }

  const closeSignin = () => {
    setSignin(false)
  }
  const [signup, setSignup] = useState(false)

  const showSignup = () => {
    setSignup(true)
  }

  const closeSignup = () => {
    setSignup(false)
  }

  let navEl = [useRef(null), useRef(null)]
  let titleEl = useRef(null)
  let bgEl = useRef(null)
  let inappNav = useRef(null)

  useEffect(() => {
    gsap.from(bgEl, {duration: 1.2, y: -20, ease: "slow(0.1, 0.4, false)"})
    gsap.to(titleEl, {opacity: 1, duration: 1.2, x: 20, ease: "slow(0.7, 0.4, false)", delay: 1})
    !user && gsap.from(navEl, {opacity: 0, duration: .8, y: -20, ease: "back", delay: 1.2, stagger: 0.1})
    user && gsap.from(inappNav, {opacity: 0, duration: .8, y: -20, ease: "back", delay: 1.2})
  }, [bgEl, titleEl])

  useEffect(() => {
    user && gsap.from(inappNav, {opacity: 0, duration: .8, y: -20, ease: "back", delay: .2})
  },[inappNav, user])


  return(
  <>
        
    <nav ref={el => bgEl = el} className="color-0 app"> 
      <div className="nav-wrapper container1">
        <div className="">
          {!user && <Link to="/" className="brand-logo navbar-brand">
            <Image       
            />
            <h3 className="brand-name" ref={el => titleEl = el}>Villa Sant&eacute;</h3>
          </Link>}
          <ul  className="right hide-on-med-and-down color-1-text">
            
            <li  className="hidden">
              <Link to="/"></Link>
            </li>
            {!user && <li ref={el => navEl[0] = el}>
              <button className="center-align villatalk" onClick={showSignup} >S'Enregistrer</button>
            </li>}
            {!user && <li ref={el => navEl[1] = el}>
              <button className="center-align villatalk" onClick={showSignin} >Se Connecter</button>
            </li>}  
             {user &&<li ref={el => (inappNav = el)}>
              <button className="center-align villatalk" onClick={() => logoutUser()} >Se Deconnecter</button>
            </li> }  
          </ul>
          {/* <MobNav /> */}
        </div>
      </div>
    </nav>
    {signin && <SignIn visible={signin} close={closeSignin} />}
    {signup && <SignUp visible={signup} close={closeSignup} />}
  </>
)};
export default Nav;