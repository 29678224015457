import React, {useRef, useEffect} from 'react';
import gsap from 'gsap'

const Select = ({field, form:{touched, errors}, ...props}) => {
  let errorEl = useRef(null)

  useEffect(() => {
    (errors[field.name] && touched[field.name]) && gsap.from(errorEl, {duration: 1.2, y: -20, opacity: 0, ease: "back"})

  },)

  return (
  <div className="input-wrapper">   
    <select className="styled-input" {...field} {...props}>{props.children}</select>
    <div className="input-error" ref={el => errorEl = el} >{errors[field.name]}</div>
  </div>
  )
};

export default Select;