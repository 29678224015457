import React, {useEffect, useRef, useState, useContext} from 'react'
import Header from '../components/headerApp'
import { gsap } from 'gsap'
import SignIn from '../components/signin'
import SignUp from '../components/signup'
import { AuthContext } from '../firebase'
import {navigate} from "gatsby"

const Default = () => {
  const userInit = {myUser: ""}
  const { authUser }  = useContext(AuthContext) || userInit
  const { user } = authUser || userInit

  const [signin, setSignin] = useState(false)

  const showSignin = () => {
    setSignin(true)
  }

  const closeSignin = () => {
    setSignin(false)
  }
  const [signup, setSignup] = useState(false)

  const showSignup = () => {
    setSignup(true)
  }

  const closeSignup = () => {
    setSignup(false)
  }

  useEffect(() => {
    user && navigate("/app/dashboard")
  }, [])

  let h1El = useRef(null)
  let pEl = useRef(null)
  let actionsEl = [useRef(null), useRef(null)]

  useEffect(() => {
    gsap.from(h1El, {opacity: 0, duration: .8, y: 20, ease: "back", delay: .4})
    gsap.from(pEl, {opacity: 0, duration: .8, y: 20, ease: "back", delay: .4})
  },[h1El, pEl])
  useEffect(() => {
    gsap.from(actionsEl, {opacity: 0, duration: .8, y: -20, ease: "back", delay: .4, stagger: 0.1})
  },[])
  return (
    <Header>
      <div className="app-home">
        <h1 ref={el => h1El = el} className="center color-0-text">Allô doc</h1>
        <p ref={el => pEl = el} className="center white-text">
          Villa santé vous offre un nouveau service de consultation en ligne.
          Tous vos problèmes gynécologiques, ceux liés à votre grossesse et vos problèmes sexuels seront pris en charge par telemedecine et teleconseil.
        </p>
        <div className="actions center">
          <button ref={el => actionsEl[0] = el} onClick={showSignin} className="villatalk">Se Connecter</button>
          <button ref={el => actionsEl[1] = el} onClick={showSignup} className="villatalk">S'Enregistrer</button>
        </div>
      {/* <StyledFirebaseAuth uiConfig={Firebase.uiConfig} firebaseAuth={Firebase.auth}/> */}
      </div>
      {signin && <SignIn visible={signin} close={closeSignin} />}
      {signup && <SignUp visible={signup} close={closeSignup} />}

    </Header>
  )
}

export default Default
