import React, {useState, useContext, useEffect} from 'react'
import {Row, Col} from 'react-materialize'
import { FirebaseContext } from '../firebase'
import { AuthContext } from '../firebase'
// import { gsap } from 'gsap'
import CompleteModal from './resources/completeModal'
import { navigate } from 'gatsby'
// import UpdatePwd from './resources/updatepwd'

const Profile = () => {

  const {authUser, setAuthUser} = useContext(AuthContext)
  const {user} = authUser
  // console.log('user', user)
  
  const firebase = useContext(FirebaseContext)
  const [dbUser, setdbUser] = useState({})
  // console.log('firebase.auth.currentUser :', firebase.auth.currentUser);
  const [isCompleted, setIsCompleted] = useState(true)

  const [complete, setComplete] = useState(false)
  const [isVerified, setIsVerified] = useState(user.emailVerified)
  const [emailSent, setEmailSent] = useState(false)

  // console.log('dbUser :', dbUser);

  const showComplete = () => {
    setComplete(true)
  }

  const closeComplete = () => {
    setComplete(false)
  }

  const setIsComplete = () => {
    setIsCompleted(true)
  }

  const handleVerification = () => {
    
    user.sendEmailVerification().then(function() {
      setEmailSent(true)
      setIsVerified(true)

    }).catch(function(error) {
    });
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setEmailSent(false)
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const deleteUser = () => {
    firebase.db.collection("user").doc(user.uid).delete().then(function(){
      console.log('delete :');
    })
    user.delete().then(function () {
      console.log('delete user fb :');
    })
    setAuthUser({user: ""})

    navigate("/app")
  }

  const updatepwd = () => {
    // <UpdatePwd />
  }

  useEffect(() => {
    getDBUser();

  }, [])

  useEffect(() => {
    getDBUser();
    // dbUser.age ? setIsCompleted(true) : setIsCompleted(false)
    dbUser.age ? console.log('true :', true) : console.log('false', false)
  }, [isCompleted])

  const getDBUser = () => {
    firebase.db.collection("user").doc(user.uid).get().then(function (doc){
      if(doc.exists){
        const myUser = {id: doc.id, ...doc.data()}
        setdbUser(myUser)
        myUser.age ? setIsCompleted(true) : setIsCompleted(false)
      }
    }).catch(function(error) {
      console.log("Error updating user:", error);
    })
  }

  console.log('dbUser :', dbUser);
  console.log('isCompleted :', isCompleted);

  return (
    <div className="in-app">
      <Row>
        <Col l={4}>
          <h3 className="in-app-head-text"> Profile</h3>
        </Col>
        <Col l={8}>
          {/* {
            isVerified ?
            ""
            :
            <div className="verify">
              <Row>
                <Col l={8}>
                  <p>Veuillez verifier votre email: </p>
                </Col>
                <Col l={4}>
                  <button onClick={handleVerification}>Verifier</button>
                </Col>
              </Row>
            </div>
          } */}
          {/* {
            emailSent 
            ?
            <div className="codesent">
              <Row>
                <p>Le code de verification a ete envoye dans votre boite aux lettres</p>
              </Row>
            </div>
            :
            ""
          } */}
          {!isCompleted ? 
            <div className="incomplete">
              <Row>
                <Col l={8}>
                  <p>Votre profile est incomplet, veuillez le completer: </p>
                </Col>
                <Col l={4}>
                  <button onClick={showComplete}>Completer</button>
                </Col>
              </Row>
            </div>
            :""
          }
        </Col>
      </Row>
      <div className="profile-bg">
        <Row>
          <Col l={6} m={6} s={12}>
            <Row>
              <Col l={12} m={12} s={12}><p className="profile-item-value"><span className="profile-item-title">Nom: </span> &nbsp; {dbUser.firstname ? dbUser.firstname : "..."}</p></Col>
            </Row>
            <Row className="hide-on-med-and-up">
              <Col l={12} m={12} s={12}><p className="profile-item-value"><span className="profile-item-title">Pr&eacute;nom: </span> &nbsp; {dbUser.lastname ? dbUser.lastname : "..."}</p></Col>
            </Row>
            <Row>
              <Col l={12} m={12} s={12}><p className="profile-item-value"><span className="profile-item-title">Email: </span> &nbsp; {dbUser.email ? dbUser.email : "..."}</p></Col>
            </Row>
            <Row>
              <Col l={12} m={12} s={12}><p className="profile-item-value"><span className="profile-item-title">Sex: </span> &nbsp; {dbUser.sex ? dbUser.sex : "..."}</p></Col>
            </Row>
            <Row>
              <Col l={12} m={12} s={12}><p className="profile-item-value"><span className="profile-item-title">Profession: </span> &nbsp; {dbUser.profession ? dbUser.profession : "..."}</p></Col>
            </Row>
            <Row>
              <Col l={12} m={12} s={12}><p className="profile-item-value"><span className="profile-item-title">Religion: </span> &nbsp; {dbUser.religion ? dbUser.religion : "..."}</p></Col>
            </Row>
          </Col>
          <Col l={6} m={6} s={12}>
            <Row className="hide-on-small-only">
              <Col l={12} m={12} s={12}><p className="profile-item-value"><span className="profile-item-title">Pr&eacute;nom: </span> &nbsp; {dbUser.lastname ? dbUser.lastname : "..."}</p></Col>
            </Row>
            <Row>
              <Col l={12} m={12} s={12}><p className="profile-item-value"><span className="profile-item-title">Phone: </span> &nbsp; {dbUser.phone ? dbUser.phone : "..."}</p></Col>
            </Row>
            <Row>
              <Col l={12} m={12} s={12}><p className="profile-item-value"><span className="profile-item-title">Age: </span> &nbsp; {dbUser.age ? dbUser.age : "..."}</p></Col>
            </Row>
            <Row>
              <Col l={12} m={12} s={12}><p className="profile-item-value"><span className="profile-item-title">Nb d'enfants: </span> &nbsp; {dbUser.children ? dbUser.children : "..."}</p></Col>
            </Row>
          </Col>
        </Row>
        <button className="villatalk">Modifier</button>
      </div>
      {complete && <CompleteModal complete={setIsComplete} userId={dbUser.id} visible={complete} close={closeComplete} />}
      {/* <h4 className="in-app-subhead-text danger">Zone Dangereuse</h4>
      <div className="profile-bg-2">
        <Row className="update">
          <Col l={10}><p className="warning">Changer votre mot de passe</p></Col>
          <Col l={2}><button  onClick={deleteUser}>Changer</button></Col>
        </Row>
        <Row className="delete">
          <Col l={10}><p className="danger">Supprimer votre compte</p></Col>
          <Col l={2}><button  onClick={deleteUser}>Supprimer</button></Col>
        </Row>
      </div> */}
    </div>
    
  )
}

export default Profile
