import React, {useContext} from 'react';
import Row from 'react-materialize/lib/Row';
import Col from 'react-materialize/lib/Col';
import { navigate } from 'gatsby';
import { AuthContext } from '../firebase'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Pdf from './resources/pdf'

const Consultation = () => {
  const consultation = window.history.state.booking
  const {authUser} = useContext(AuthContext)
  const {user} = authUser

  function formatDate(date) {
    
    const year = '' + date.getFullYear()
    const month = (date.getMonth() < 10 ? '0' : '') + (1 + date.getMonth())
    const day =  (date.getDate() < 10 ? '0' : '') +  date.getDate()
    // const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
    // const minute = (date.getMinutes()  < 10 ? '0' : '') + date.getMinutes()
    // const secondes = (date.getSeconds()  < 10 ? '0' : '') + date.getSeconds()
    const myDate = day + '-' + month + '-' + year 
    // + '-' + hour + '-' + minute + '-' + secondes

    return myDate
  
  }

  function handleClick(info) {
    navigate(`/app/exams/${user.uid}`, {state:{info},})
  }

  console.log('consultation :>> ', consultation);
  return (
    <div className="in-app">
      <div className="consultation">
        {/* <p className="color-2 greetings">Hello <span>{user.fullName}</span>,</p> */}
        <div className="consultation-title center">
          <p className="color-1-text"><span>Date:</span>{formatDate(consultation.startDate)}</p>
        </div>
        <div className="consultation-body">
          <p className="consultation-subhead color-1-text">Trouvailles Clinique</p>
          <p className="consultation-summary color-1-text">{consultation.finding}</p>
          <div className="exams">
            <p className="consultation-subhead color-1-text">Examens de labo</p>
            <Row>
              <Col s={9}>
                <p className="consultation-summary color-1-text">{consultation.exams}</p>              
              </Col>
              <Col s={3}>
                <button className="mybtn color-0">

                <PDFDownloadLink document={<Pdf data={consultation.exams} user={user} type="Examens de Laboratoire" date={formatDate(consultation.startDate)} />} fileName={`Examens_${user.firstname}_${user.lastname}_${formatDate(consultation.startDate)}`}>
                  {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Telecharcher!')}
                </PDFDownloadLink>
                </button>
              </Col>
            </Row>
          </div>
          <p className="consultation-subhead color-1-text">Prescriptions</p>
          <Row>
            <Col s={9}>
              <p className="consultation-summary color-1-text">{consultation.medication}</p>              
            </Col>
            <Col s={3}>
              <button className="mybtn color-0">

              <PDFDownloadLink document={<Pdf data={consultation.medication} user={user} type="Prescription" date={formatDate(consultation.startDate)} />} fileName={`Prescription_${user.firstname}_${user.lastname}_${formatDate(consultation.startDate)}`}>
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Telecharcher!')}
              </PDFDownloadLink>
              </button>
            </Col>
          </Row>
        </div>
        
      </div>
    </div>
  );
};

export default Consultation;