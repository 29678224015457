import React, {useRef, useEffect} from 'react';
import styled from 'styled-components'
import gsap from 'gsap'

const TextareaWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  &:not(:last-child){
    margin-bottom: 1.5rem !important;
  }
`

const StyledTextarea = styled.textarea`
  position: relative !important;
  padding: .5rem 1rem !important; 
  background-color:  rgb(29,17,83) !important;
  min-height: 4.5rem !important;
  box-sizing:border-box !important;
  border-bottom: none !important;
  border-radius: 1rem !important;
  margin-bottom: .5rem !important;

  color: white;

  &::placeholder {
    color: #fff !important;
    font-size: .85rem;
    
  }
`

const Error = styled.div`
  color: #F44336;
  font-size: .8rem;
  position: absolute;
  left: 0;
  bottom: -20px;
`

const Textarea = ({field, form:{touched, errors}, ...props}) => {
  let errorEl = useRef(null)

  useEffect(() => {
    (errors[field.name] && touched[field.name]) && gsap.from(errorEl, {duration: 1.2, y: -20, opacity: 0, ease: "back"})

  },)

  return (
  <TextareaWrapper>   
    <StyledTextarea {...field} {...props}>{props.children}</StyledTextarea>
    <Error ref={el => errorEl = el} >{errors[field.name]}</Error>
  </TextareaWrapper>
  )
};

export default Textarea;