import React from "react"
import BackgroundImage from "./bgimage"
// import { FaLinkedinIn, FaFacebookF, FaTwitter } from 'react-icons/fa'
// 



const Header = (props) =>{

  
  return (
    <header className="header">
      <BackgroundImage
        className="bgimage"
      />
      {props.children}
    </header>
  )
}
;
export default Header;