import React, {useState, useEffect, useContext} from 'react'
import {
  AvailabilityCalendar
  // AvailabilityEvent,
  // MsSinceMidnightRange,
  // Booking,
  // Range,
  // CalendarThemeProp,
} from 'react-availability-calendar'
import { FirebaseContext } from '../firebase'
import { AuthContext } from '../firebase'
import * as CONST from "./constants/constants"
import CountUp from 'react-countup';
import {gsap} from 'gsap'
import moment from 'moment'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import Image from '../components/image3'
import {Formik, Field} from 'formik'
import * as Yup from 'yup'
import Input from '../UI/Form/input'
import {StyledForm, StyledButton} from '../UI/elements'
import { navigate } from 'gatsby';
import Textarea from '../UI/Form/textarea'


const PaymentSchema = Yup.object().shape({
  txId: Yup.number('Email invalide').required('Veuillez entrer votre ID de transactions'),
})

const MotifSchema = Yup.object().shape({
  motif: Yup.string('Motif insuffisant').required('Veuillez entrer votre motif de consultation'),
})

const msInHour = 60 * 60 * 1000
const msInMinute = 60 * 1000

const Scheduler = () => {

  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  const firebase = useContext(FirebaseContext)
  const [bookings, setBookings] = useState([])
  const [books, setBooks] = useState([])
  const [selectedSlots, setSelectedSlots] = useState(0)
  const [paymentSent, setPaymentSent] = useState(false)
  const [motif, setMotif] = useState(null)
  const [orderId, setOrderId] = useState(null)



  useEffect(() => {
    getBookings()
  }, [])

  // const today = Date.now() -604800000

  const today = Date.now()
  const todayObj = new Date(today)

  const getBookings = () => {
    firebase.db.collection("orders").where('booking.startDate', '>=', todayObj).onSnapshot(handleSnapshot)
  }


  const setBooking = (booking)  => {
    setBooks([...books, booking])
    setBookings([...bookings, booking])
    localStorage.setItem('bookings', JSON.stringify(books))
    setSelectedSlots(selectedSlots + 1)    
  }

  const handleSnapshot = (snapshot) => {
    let mybookings = []  
    snapshot.docs.map(doc =>{
        if((doc.data().status === "completed" )|| (doc.data().status === "pending")){

          mybookings.push({startDate: doc.data().booking.startDate.toDate(), endDate: doc.data().booking.endDate.toDate()})
        }

    })
    setBookings(mybookings)    
  }

  const createOrder = (id, startDate, endDate) => {
    firebase.db.collection("orders").doc(id).set({userId: user.uid, doctorId: CONST.DOCTOR, motif: motif, orderId: id, status: "pending", transactionDate: new Date(now), booking: {startDate: startDate, endDate: endDate}})

  }

  useEffect(() => {
    localStorage.setItem('bookings', JSON.stringify(books))
  }, [books])


  const now = new Date()

  const onAvailabilitySelected = (a) =>{

    const orderId = "APT" + "-" + dateFormat(now) + "-" + user.uid
    setOrderId(orderId)
    const mybook = {start: a.startDate, end: a.endDate}
    // localStorage.setItem('orderId', JSON.stringify(orderId))
    localStorage.setItem('booking', JSON.stringify(mybook))
    const startDate = new Date(a.startDate)
    const endDate = new Date(a.endDate)
    setBooking({startDate, endDate})
    // console.log('startDate', startDate)
    createOrder(orderId, startDate, endDate)
  }
    
  const onChangedCalRange = (r) =>
    console.log('Calendar range selected (fetch bookings here): ', r);
 
  const blockOutPeriods = [
    [0 * msInHour, 7 * msInHour],
    [8.5 * msInHour, 20 * msInHour],
    [23 * msInHour, 24 * msInHour],
  ];

  const slotLengthMs = 20 * msInMinute
  const slotStepMs = 20 * msInMinute

  const theme={requestAppointmentLabel: "Choisissez la tranche horaire qui vous convient"}
 
  const providerTimeZone = 'America/New_York';

  const dateFormat = (date) => {
    const year = '' + date.getFullYear()
    const month = (date.getMonth() < 9 ? '0' : '') + (1 + date.getMonth())
    const day =  (date.getDate() < 10 ? '0' : '') +  date.getDate()
    const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
    const minute = (date.getMinutes()  < 10 ? '0' : '') + date.getMinutes()
    const secondes = (date.getSeconds()  < 10 ? '0' : '') + date.getSeconds()

    const myDate = year + month + day + hour + minute + secondes

    return myDate
  }

  const handlePayment = () => {
    setPaymentSent(true)
    let orderData = {
      amount : selectedSlots * CONST.PRICE,
      orderId: orderId
    }

    fetch('https://us-central1-villasanteapp.cloudfunctions.net/moncashPayment', {
      method: 'POST',
      body: JSON.stringify(orderData)
    }).then(res => {
      return res.json();
    }).then(data =>{
      const body = JSON.parse(data.body)
      // window.open(body.paymentUrl, '_blank')
      window.open(body.paymentUrl, '_self')
    })
  }

  const confirmPayment = () =>{
    navigate('/app/return')
  }

  useEffect(() => {
    selectedSlots && gsap.to(".payment-container", {autoAlpha: 1, display: 'block', y:-20, duration: 0.5, ease: "back"})
    // motif && gsap.to(".calendar", {opacity: 1, y:-20, duration: 0.5, ease: "back"})
    selectedSlots && gsap.to(".calendar", {opacity: 0, display: 'none', y:20, duration: 0.5, ease: "back"})
    selectedSlots && gsap.to(".payment-text", {opacity: 1, y:-20, duration: 0.5, ease: "back", delay: 0.2})
    selectedSlots && gsap.to(".payment-amount-container", {opacity: 1, y:-20, duration: 0.5, ease: "back", delay: 0.4})
    selectedSlots && gsap.to("#payment-btn", {opacity: 1, y:-20, duration: 0.5, ease: "back", delay: 0.5})
  }, [selectedSlots])

  useEffect(() => {
    motif && gsap.to(".calendar", {autoAlpha: 1, display: 'block', y:-20, duration: 0.5, ease: "back", delay: 0.5})
    !motif && gsap.to(".motif", {autoAlpha: 1, y:20, display: "block", duration: 1.5, ease: "back"})
    motif && gsap.to(".motif", {autoAlpha: 0, display: "none", y:-20, duration: 0.5, ease: "back"})

  }, [motif])



  return (
    <div className="in-app">
      <Row>
        <Col l={12}>
          <h3 className="in-app-head-text"> Rendez-vous</h3>
        </Col>
      </Row>
      <Row>
        <Col l={12}>
          <p className="in-app-body-text">Prenez rndez-vous ici. Cliquez sur la date que vous voulez pour choisir la tranche d'heure que vous voulez. Les tranches sont d'une dur&eacute;e de <span>20 minutes</span> et co&ucirc;tent <span>{CONST.PRICE} {CONST.CURRENCY}</span> par tranche. Le paiement se fait par <span>Moncash</span>. Assurez-vous d'avoir les fonds disponibles sur Moncash avant de commencer le processus. La prise de rendez-vous se fait en 3 &eacute;tapes:</p>
        </Col>
        <Col l={4} m={4} s={12}><p className="steps">1- Mettre le motif de la consultation</p></Col>
        <Col l={5} m={4} s={12}><p className="steps">2- Choisir la date et la tranche d'heure</p></Col>
        <Col l={3} m={4} s={12}><p className="steps">3- Faire le paiement</p></Col>
      </Row>
        <div className="motif">
          <h5 className="center">Motif</h5>
          <Formik
            initialValues={{
              motif: '',
            }}
            validationSchema={MotifSchema}
            onSubmit={(values, {setSubmitting}) =>{
              setMotif(values.motif)
              localStorage.setItem("motif", JSON.stringify(values.motif))
            }}
          >
            {({isSubmitting, isValid}) => (
              <StyledForm>
                <Field
                  type='number'
                  name='motif'
                  placeholder='Le motif de votre rendez-vous'
                  component={Textarea}
                />

                <StyledButton className="color-0 color-1-text" type='submit'>Envoyer</StyledButton>
                
              </StyledForm>           
            )}
          </Formik>          
        </div>
        <>
          <div className="calendar">
            <h5>Choisissez la date et l'heure</h5>
              <AvailabilityCalendar
                bookings={bookings}
                theme={theme}
                providerTimeZone={providerTimeZone}
                moment={moment}
                initialDate={now}
                onAvailabilitySelected={onAvailabilitySelected}
                onCalRangeChange={onChangedCalRange}
                blockOutPeriods={blockOutPeriods}
                slotLengthMs={slotLengthMs}
                slotStepMs={slotStepMs}
                // excludeFn={d => d.getDay() === 0}
              />
          </div>
          <div className="payment-container center">
          {
            paymentSent
            ?
            <>
              <p className="payment-sent">
                Merci pour votre paiement. Vous allez &ecirc;tre redirig&eacute; vers la plateforme Moncash pour effectuer le paiement. Une fois le processus termin&eacute; Moncash vous renverra ici. Si le processus de paiement s'est boucl&eacute; et vous n'&ecirc;tes pas revenu sur notre site, vueillez nous contacter sur <a href="mailto:info@villasantehaiti.com">info@villasantehaiti.com</a>
              </p>
              {/* <Formik
                initialValues={{
                  txId: '',
                }}
                validationSchema={PaymentSchema}
                onSubmit={(values, {setSubmitting}) =>{
                  // setTxId(values.txId)
                  localStorage.setItem('txId', JSON.stringify(values.txId))
                  confirmPayment()
                  console.log('values', values)
                }}
              >
                {({isSubmitting, isValid}) => (
                  <StyledForm>
                    <Field
                      type='number'
                      name='txId'
                      placeholder='Id de transaction de Moncash ...'
                      component={Input}
                    />

                    <StyledButton className="color-0 color-1-text" type='submit'>Envoyer</StyledButton>
                    
                  </StyledForm>           
                )}
              </Formik> */}
            </>
            :
            <>
              <p className="payment-text">Votre totale est </p>
              <div className="payment-amount-container">
                <div className="payment-amount">
                  <h4>
                  <CountUp end={selectedSlots * CONST.PRICE} />
                  </h4>
                  <p>{CONST.CURRENCY}</p>
                </div>          
              </div>
              <button id="payment-btn" onClick={handlePayment} >
                <Image />
              </button>
            </>
          }
        </div>
        </>
    </div>
  )
}

export default Scheduler
