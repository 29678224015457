import React, {useContext} from 'react';
import {Formik, Field} from 'formik'
import * as Yup from 'yup'
import Textarea from '../../UI/Form/textarea'
import {StyledForm, StyledButton} from '../../UI/elements'
import * as CONST from "../constants/constants"
import { FirebaseContext } from '../../firebase'



const MotifSchema = Yup.object().shape({
  motif: Yup.string('Motif insuffisant').required('Veuillez entrer votre motif de consultation'),
})

const UpdateBookings = ({userId, orderId, bookings, sent}) => {

  const firebase = useContext(FirebaseContext)

  const updateOrder = (id, motif) => {
    const orderRef = firebase.db.collection("orders").doc(id)

    orderRef.update({userId: userId, transactionDate: new Date(), doctorId: CONST.DOCTOR, firstBookingDate: new Date(bookings[0].startDate), bookings: bookings, status: "completed", motif: motif})

  }

  return (
    <div>
      <p className="color-1-text greetings margbottom">Merci pour votre reservation. Veuillez mettre le motif de votre rendez-vous.</p>
      <Formik
        initialValues={{
          motif: '',
        }}
        validationSchema={MotifSchema}
        onSubmit={(values, {setSubmitting}) =>{

          updateOrder(orderId, values.motif)
          sent()
          localStorage.removeItem('bookings')
          console.log('values', values)
        }}
      >
        {({isSubmitting, isValid}) => (
          <StyledForm>
            <Field
              type='number'
              name='motif'
              placeholder='Le motif de votre rendez-vous'
              component={Textarea}
            />

            <StyledButton className="color-0 color-1-text" type='submit'>Envoyer</StyledButton>
            
          </StyledForm>           
        )}
      </Formik>
      
    </div>
  );
};

export default UpdateBookings;