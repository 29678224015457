import React, { useContext, forwardRef } from 'react';
import {Formik, Field} from 'formik'
import * as Yup from 'yup'
import { FirebaseContext } from '../../firebase'
import Input from '../../UI/Form/input'
import {StyledForm, StyledButton} from '../../UI/elements'
import { navigate } from 'gatsby'
import { AuthContext } from '../../firebase'


const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required('Veuillez entrer votre email'),
  password: Yup.string().required('Veuillez entrer votre mot de passe'),
})



const Login = forwardRef((props, ref) => {

  const Firebase = useContext(FirebaseContext)
  const { setAuthUser } = useContext(AuthContext)
  
  const authenticateUser = async (values) => {
    const { email, password} = values
    const response = await Firebase.login(email, password)
    setAuthUser({user: response})
    localStorage.setItem('user', JSON.stringify(response))
    navigate("/app/dashboard")
  }

  const getDBUser = (myuser) => {
    Firebase.db.collection("user").doc(myuser.ui).get().then(function (doc){
      if(doc.exists){
        return {id: doc.id, ...doc.data()}        
      }
    }).catch(function(error) {
      console.log("Error updating user:", error);
    })
  }

  return (
    <div className="form-wrapper">
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, {setSubmitting}) =>(
          authenticateUser(values)
        )}
      >
        {({isSubmitting, isValid, handleReset}) => (
          <StyledForm ref={ref}>
            <Field
              type='email'
              name='email'
              placeholder='Votre email ...'
              component={Input}
            />
            {/* <ErrorMessage name='email' /> */}
            <Field
              type='password'
              name='password'
              placeholder='Votre password ...'
              component={Input}
            />
            {/* <ErrorMessage name='password' /> */}
            <StyledButton className="color-0 color-1-text" type='submit'>Envoyer</StyledButton>
            {props.close && handleReset}
          </StyledForm>           
        )}
      </Formik>
    
    </div>
  );
});

export default Login;