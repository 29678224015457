import React, {useContext, useState, useEffect} from 'react'
import { AuthContext } from '../firebase'
// import {Row, Col, CardPanel} from 'react-materialize'
import useQueryString from 'use-query-string';
import { FirebaseContext } from '../firebase'
import * as CONST from "./constants/constants"
import UpdateBookings from './resources/updateBookings';
// import { now } from 'moment';
import AddToCalendar from 'react-add-to-calendar';
import { google, outlook, office365, yahoo, ics } from "calendar-link";

const Return = (props) => {
  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  const firebase = useContext(FirebaseContext)
  const [query, setQuery] = useQueryString(window.location)
  const [transaction, setTransaction] = useState(query.transactionId || null)

  const sendEmail = (dest, mail) =>{
    const motif = JSON.parse(localStorage.getItem('motif')) || ""
    const booking = JSON.parse(localStorage.getItem('booking')) || ""

    const name = dest === 'patient'?user.firstname:'Dr René'
    const email = mail
    const subject = dest === 'patient'?'Consultation avec Dr Garry René':`Consultation avec ${user.firstname} ${user.lastname}`
    const message = dest === 'patient'?`Mecri d'avoir reserver votre consultation avec Dr René pour: ${motif}. Veuillez revenir sur le site à l'heure de votre rendez-vous pour recevoir la consultation.`:`Vous avez une consultation avec ${user.firstname} pour: ${motif}. Veuillez vous connecter sur le site à l'heure du rendez-vous pour faire la consultation.`

    const event = {
      title: subject,
      description: motif,
      location: 'en ligne',
      start: new Date(booking.start),
      end: new Date(booking.end)
    }

    // console.log('date :>> ', new Date(booking.start));

    const googleLink = google(event)
    const outlookLink = outlook(event)
    const office365Link = office365(event)
    const yahooLink = yahoo(event)

    fetch('https://us-central1-villasanteapp.cloudfunctions.net/sendEmail', {
      method: 'POST',
      body: JSON.stringify({name, email, subject, message, googleLink, outlookLink, office365Link, yahooLink})
    }).then(res => {
      return res.json();
    })

  }

  useEffect(() => {
    sendEmail('patient', user.email)
    sendEmail('doc', 'louishugens@gmail.com')
    sendEmail('doc', 'drgrene@gmail.com')
  }, [])


  
  return (
    <div className="in-app">
      <h3 className="in-app-head-text"> Confirmation de paiment</h3>
      <div className="mycard vertical-center">
        {/* { 
          (transactionExist === null) && <p className="color-1-text greetings">...</p>
        }   */}

        {/* {
          (transactionExist === "not found") && <p className="color-1-text greetings">Transaction introuvable. Veuillez v&eacute;rifier que vous avez re&ccedil;u le message de confirmation sur votre t&eacute;l&eacute;phone. Sinon contactez Moncash.</p>
        }  */}

        {/* {
          (transactionExist === "found") && <UpdateBookings bookings={formattedBookings} orderId={orderId} userId={user.uid} sent={orderSent}/>
        }
        {
          (transactionExist === "in use") && <p className="color-1-text greetings">Le code de confirmation a d&eacute;j&agrave; &eacute;t&eacute; utilis&eacute;. Veuillez nous contacter pour plus de details.</p>
        } */}

          <p className="color-1-text greetings">Merci pour votre r&eacute;servation. Vous recevrez un email aussi.</p>

      </div>
     
    </div>
  )
}

export default Return
