import React from 'react';

const Futurebookings = ({bookings}) => {

  function formatDate(date) {
    
    const year = '' + date.getFullYear()
    const month = (date.getMonth() < 10 ? '0' : '') + (1 + date.getMonth())
    const day =  (date.getDate() < 10 ? '0' : '') +  date.getDate()
    const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
    const minute = (date.getMinutes()  < 10 ? '0' : '') + date.getMinutes()
    const secondes = (date.getSeconds()  < 10 ? '0' : '') + date.getSeconds()

    const myDate = year + '-' + month + '-' + day + '-' + hour + '-' + minute + '-' + secondes

    return myDate
  
  }

  return (
    <div className="future">
      <h6 className="future-title color-1-text">Consultations Futures</h6>
      <div className="future-list">
        {
          bookings.map( booking =>{
            return(
              <div className="future-list-item">
                <p>D&eacute;but: <span>{formatDate(booking.startDate)}</span></p>
                <p>Fin: <span>{formatDate(booking.endDate)}</span></p>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default Futurebookings;