import React, {useContext, useCallback, useState} from 'react'
import {AuthContext } from '../../firebase'
import axios from 'axios'
import Room from './Room'
import Col from 'react-materialize/lib/Col'
import moment from 'moment-timezone'
import Timer from 'react-compound-timer'
import {FaPhone} from 'react-icons/fa';
import {DateTime} from 'luxon'



const Appointment = ({booking}) => {
  const now = new Date()
  // const now = moment().tz('America/Resolute')
  console.log('now :>> ', now);
  // .setZone('America/Los_Angeles')
  const [isDisabled, setIsDisabled] = useState(moment(now).isBetween(booking.startDate, booking.endDate))
  const { authUser } = useContext(AuthContext)
  const { user } = authUser
  const [token, setToken] = useState(null)

  const handleClick = async () => {
    const result = await axios({
      method: 'POST',
      url: 'https://timberwolf-lynx-1724.twil.io/create-token',
      data:{
        identity: user.firstname
      }
    })
    setToken(result.data)
  }



  const roomName = booking.roomName

  const handleLogout = useCallback(event => {
    setToken(null);
  }, [])

  const start = moment(booking.startDate).diff(now)

  const handleTimerStart = () => {
    setIsDisabled(!isDisabled)
  }


  return (
    <Col l={4} m={4} s={12}>
      {
        !token 
        ? 
        <button 
          onClick={handleClick} 
          disabled={!isDisabled} 
          className="appointment"
        >
          {
          !isDisabled
          ?
          <>
            <h6>Votre rendez-vous commence dans:</h6>
            <Timer
              initialTime={start}
              direction="backward"
              checkpoints={[
                {
                    time: 0,
                    callback: () => {
                      handleTimerStart()
                    },
                },
              ]}
            >
              <>
                <h6 className="timer">
                  <Timer.Days /> <span>Jours </span>
                  <Timer.Hours /> <span> Heures </span>
                </h6>
                <h6 className="timer">
                  <Timer.Minutes /> <span>Minutes </span>
                  <Timer.Seconds /> <span>Secondes</span>
                </h6>            
              </>
            </Timer>
          </>
           :
          <>
            <p>L'heure de votre rendez-vous est arriv&eacute;e. Cliquez ici pour lancer l'appel.</p>
            <div className="circle call-btn">
              <FaPhone className="reverse" />
            </div>
          </>


          } 
        </button>
        : 
        <Room roomName={roomName} endTime={booking.endDate} token={token} handleLogout={handleLogout} />
      }
    </Col>
  );
};

export default Appointment;