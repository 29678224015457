import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { FaTimes } from "react-icons/fa";
import Login from '../Auth/Login/Login'



const SignIn = props => {
  let modalVeil = null;
  let modalDialog = null;
  let modalContent = null;
  let modalHead = null

  const [modalTween] = useState(gsap.timeline({ paused: true }));

  useEffect(() => {
    modalTween
      .to(modalVeil, 0.25, { autoAlpha: 1 })
      .to(modalDialog, 0.35, { y: 0, autoAlpha: 1 })
      .from(modalHead, 0.35, {y: 15, opacity: 0, ease: "back"})
      .from(
        modalContent.children,
        0.35,
        { y: 15, opacity: 0, stagger: 0.1 },
        "-=0.15"
      )
      .from(
        "#close1", 
        0.35, 
        { y: 15, opacity: 0 }
      )
      .reverse();
  }, [modalVeil, modalDialog, modalTween, modalHead, modalContent, props.visible]);

  useEffect(() => {
    modalTween.reversed(!props.visible);
  }, [props.visible, modalTween]);

  const closeModal = () => {
    modalTween.reverse();
    gsap.delayedCall(modalTween.duration(), props.close);
  };

  return (
    <div className={`modal-container ${props.visible ? " show" : ""}`}>
      <div className="modal-veil" role="button" tabIndex={0} ref={e => (modalVeil = e)} onClick={closeModal} onKeyDown={closeModal}>
        <FaTimes id="close1" className="close white-text" />
      </div>
      <div className="sign-dialog white" ref={e => (modalDialog = e)}>
        <h3 ref={e => (modalHead = e)} className="center color-1-text">Connectez-vous</h3>
        <Login ref={e => (modalContent = e)} />   
      </div>
    </div>
  );
};

export default SignIn;