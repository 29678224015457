import React, { useState, useEffect, useContext } from 'react';
import Video from 'twilio-video';
import Participant from './Participant';
import {FaPhone} from 'react-icons/fa';
import { isMobileOnly } from 'react-device-detect';
import Timer from 'react-compound-timer'
import moment from 'moment'
import { AuthContext } from '../../firebase'
import { gsap } from 'gsap'
import AddFindins from './AddFindins';

const Room = ({ roomName, token, handleLogout, endTime }) => {
  const now = new Date()
  const [room, setRoom] = useState(null);
  const [addInfo, setAddInfo] = useState(false)
  const [participants, setParticipants] = useState([]);
  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  

  useEffect(() => {

    addInfo && gsap.to(".findings", {x:20, opacity: 1, display: "block", duration: 0.5})
    !addInfo && gsap.to(".findings", {x:-20, opacity: 0, display: "none", duration: 0.5})

  }, [addInfo])

  const remoteParticipants = participants.map(participant => (
    <Participant key={participant.sid} participant={participant} myStyle={remoteStyle} />
  ));

  const width = window.innerWidth
  const height = window.innerHeight
  
  console.log('roomName :>> ', roomName);

  const end = moment(endTime).diff(now)

  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => [...prevParticipants, participant]);
    };
    const participantDisconnected = participant => {
      setParticipants(prevParticipants =>
        prevParticipants.filter(p => p !== participant)
      );
    };
    Video.connect(token, {
      maxAudioBitrate: 16000, //For music remove this line
      video: { height: height, frameRate: 24, width: width },
      audio: true,
      name: roomName
    }).then(room => {
      setRoom(room);
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      room.participants.forEach(participantConnected);
    });

    return () => {
      setRoom(currentRoom => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function(trackPublication) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);

  let localStyle = isMobileOnly ? { height: "25vh", borderRadius: "0.5rem" } : { width: "25vw", borderRadius: "0.5rem" }
  let remoteStyle = isMobileOnly ? { height: "100vh" } : { width: "100vw" }

  function showAdd() {
    setAddInfo(!addInfo)
  }

  return (
    
    <div className="mediabg">
      <Timer
        initialTime={end}
        direction="backward"
        checkpoints={[
          {
              time: 0,
              callback: () => {
                handleLogout()
              },
          },
        ]}
      >
        <>
          <h6 className="timer-video">
            <Timer.Minutes /> <span>mn: </span>
            <Timer.Seconds /> <span>s restantes</span>
          </h6>            
        </>
      </Timer>
      <button className="leaveChat circle" onClick={handleLogout}>
        <FaPhone className="down" />
      </button>
      {user.isDoctor && <button className="addInfo" onClick={showAdd}>
        Ajouter
      </button>}
      <div className="findings">
        <AddFindins close={showAdd} orderId={roomName} />
      </div>
      <div id="localMedia" className="local-participant">
        {room ? (
          <Participant
            key={room.localParticipant.sid}
            participant={room.localParticipant}
            myStyle={localStyle}
          />
        ) : (
          ''
        )}
      </div>
      {/* <h3>Remote Participants</h3> */}
      <div id="remoteMedia"  className="remote-participants">{remoteParticipants}</div>

    </div>
    
  );
};

export default Room;