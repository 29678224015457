import React, {useContext, useEffect, useState} from 'react'
import { AuthContext } from '../firebase'
import { FirebaseContext } from '../firebase'
import moment from 'moment'
import * as CONST from "./constants/constants"
// import {Row, Col, CardPanel} from 'react-materialize'
import Appointments from './resources/appointments'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import { navigate } from 'gatsby'
import Futurebookings from './resources/futurebookings'

const Dashboard = () => {
  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  const firebase = useContext(FirebaseContext)
  
  const [pastBookings, setPastBookings] = useState([])
  const [futureBookings, setFutureBookings] = useState([])


  useEffect(() => {
    getBookings()
  }, [])

  const today = Date.now() 
  const todayObj = new Date(today)
  const getBookings = () => {
    user.isDoctor
    ?
    firebase.db.collection("orders").where("status", "==", "completed").orderBy("booking.startDate", "desc").onSnapshot(handleSnapshot)
    :
    firebase.db.collection("orders").where('userId', '==', user.uid).where('status', '==', 'completed').orderBy("booking.startDate", "desc").onSnapshot(handleSnapshot)
  }

  const handleSnapshot = (snapshot) => {
    let future = []  
    let past = []  

    snapshot.docs.map(doc =>{
      if(moment(doc.data().booking.endDate.toDate()).isAfter(today)){
        future.push({roomName: doc.id, startDate: doc.data().booking.startDate.toDate(), endDate: doc.data().booking.endDate.toDate()})
      }
      else {
        past.push({roomName: doc.id, startDate: doc.data().booking.startDate.toDate(), endDate: doc.data().booking.endDate.toDate(), motif: doc.data().motif, finding: doc.data().finding, exams: doc.data().exams, medication: doc.data().medication})
        // if(moment(doc.data().booking.endDate.toDate()).diff(today) <= -900000){
        //   console.log('diff :>> ', moment(doc.data().booking.endDate.toDate()).diff(today));
        // }
      }
      console.log('doc.data() :>> ', doc.data());
    })



    setPastBookings(past)
    setFutureBookings(future)
  }

  console.log('pastBookings :>> ', pastBookings);
  console.log('futureBookings :>> ', futureBookings);

  function handleSchedule(){
    navigate('/app/scheduler')
  }

  function handleRoom(){
    navigate('/app/room')
  }

  function formatDate(date) {
    
    const year = '' + date.getFullYear()
    const month = (date.getMonth() < 10 ? '0' : '') + (1 + date.getMonth())
    const day =  (date.getDate() < 10 ? '0' : '') +  date.getDate()
    const hour = (date.getHours() < 10 ? '0' : '') + date.getHours()
    const minute = (date.getMinutes()  < 10 ? '0' : '') + date.getMinutes()
    const secondes = (date.getSeconds()  < 10 ? '0' : '') + date.getSeconds()
    const myDate = year + '-' + month + '-' + day + '-' + hour + '-' + minute + '-' + secondes

    return myDate
  
  }

  function handleBooking(booking) {
    navigate(`/app/consultations/${booking.roomName}`, {state:{booking},})
  }

  return (
    <div className="in-app">
      <p className="color-1-text greetings">Hello {user.isDoctor && <span>Dr</span>} <span>{user.isDoctor ? user.lastname : user.firstname}</span>,</p>
      <div className="dashboard">
        {/* <div className="mycard dash-pos">
          <Row>
            <Col className="center" l={6}>
              <button className="dash-cta color-1 white-text" onClick={handleSchedule}>Prendre Rendez-vous</button>
            </Col>
            <Col className="center" l={6}>
              <button className="dash-cta color-0 color-1-text" onClick={handleRoom}>Salle de Consultation</button>
            </Col>
          </Row>
        </div> */}
        <div className="past-bookings">
          <h6 className="past-bookings-head color-1-text">Consultations pass&eacute;es</h6>
          <Row>
            {
              pastBookings.map(booking => {
                return(
                  <Col l={4} m={6} s={12}>
                    <div className="past-list-item center">
                      <p>Date: <span>{formatDate(booking.startDate)}</span></p>
                      <button onClick={() => handleBooking(booking)} className="mybtn color-0 color-1-text">Voir</button>
                    </div>
                  </Col>
                )
              })
            }
          </Row>
        </div>
        <div className="hide-on-med-and-down">
          <Futurebookings bookings={futureBookings} />
        </div>
      </div>
  </div>
  )
}

export default Dashboard
