import React, {useState, useEffect, useContext} from 'react'
import Video from './video/Video'
import Row from 'react-materialize/lib/Row'
import Col from 'react-materialize/lib/Col'
import { navigate } from 'gatsby'
import { FirebaseContext } from '../firebase'
import { AuthContext } from '../firebase'
import moment from 'moment'
import * as CONST from "./constants/constants"
import {DateTime} from 'luxon'


const Room = () => {
  const {authUser} = useContext(AuthContext)
  const {user} = authUser
  const firebase = useContext(FirebaseContext)
  // const doctor = `${process.env.GATSBY_DOCTOR_ID}`

  const [userBookings, setUserBookings] = useState([])

  // const [isDoctor, setIsDoctor] = useState((user.uid == CONST.DOCTOR))
  // const [isDoctor, setIsDoctor] = useState((user.uid == doctor))

  const handleSchedule = () => {
    navigate("/app/scheduler")
  }

  useEffect(() => {
    getBookings()
  }, [])


  const today = Date.now() 
  
  // const today = DateTime.local().setZone('America/Los_Angeles')
  const todayObj = new Date(today)
  const getBookings = () => {
    user.isDoctor
    ?
    firebase.db.collection("orders").where("booking.endDate", ">=", todayObj).where("status", "==", "completed").onSnapshot(handleSnapshot)
    :
    firebase.db.collection("orders").where('userId', '==', user.uid).where('status', '==', 'completed').where('booking.endDate', '>=', todayObj).onSnapshot(handleSnapshot)
  }

  console.log('isDoctor', user.isDoctor)

  const handleSnapshot = (snapshot) => {
    let mybookings = []  

    snapshot.docs.map(doc =>{
        console.log('doc.data()', doc.data())
        if(moment(doc.data().booking.endDate.toDate()).isAfter(today)){
          mybookings.push({roomName: doc.id, startDate: doc.data().booking.startDate.toDate(), endDate: doc.data().booking.endDate.toDate()})
        }


    })
    setUserBookings(mybookings)
  }
  return (
  <div className="in-app">
    <h3 className="in-app-head-text">Salle de consultation</h3>
    {/* Ajouter condition pour render sois button sois welcome */}
    {
      userBookings.length 
      ?
      <p className="in-app-body-text">Bienvenue dans la salle de consultation. Vous pourrez cliquer sur le bouton du prochain rendez-vous quand l'heure sera venue.</p>
      :
      <div className="scheduleFirst">
        <Row>
          <Col l={9}>
            <p>{user.isDoctor ? "Nous n'avez pas de consultation programmée":"Prenez d'abord rendez-vous ici"}</p>
          </Col>
          {!user.isDoctor && <Col l={3}>
            <button onClick={handleSchedule}>Rendez-vous</button>
          </Col>}
        </Row>
      </div>
    }
    {userBookings.length ? <Video bookings={userBookings} /> : ""}
  </div>
  )
}

export default Room
