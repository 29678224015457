import React from 'react'
import { Router } from '@reach/router'
import Dashboard from '../app/dashboard'
import Default from '../app/default'
import Profile from '../app/profile';
import Room from '../app/room';
import Return from '../app/return';
import Scheduler from '../app/scheduler';
import PrivateRoute from '../app/PrivateRoute'
import AppLayout from "../components/appLayout"
import Consultation from '../app/consultation';
import Pdf from '../app/resources/pdf';


const App = () => {
  return(
    <AppLayout>
    <Router basepath="/app">
      <PrivateRoute path="/dashboard" component={Dashboard}/>
      <PrivateRoute path="/room" component={Room}/>
      <PrivateRoute path="/return" component={Return}/>
      <PrivateRoute path="/scheduler" component={Scheduler}/>
      <PrivateRoute path="/profile" component={Profile}/>
      <PrivateRoute path="/consultations/:id" component={Consultation}/>
      <PrivateRoute path="/exams/:userid" component={Pdf}/>
      <Default path="/"/>
    </Router>
    </AppLayout>
  )
}

export default App
