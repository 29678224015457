/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useContext} from "react"
import PropTypes from "prop-types"
import "./layout.css"
import Nav from "./appNav"
import Sidenav from "../UI/Sidenav"
import { AuthContext } from '../firebase'


const AppLayout = ({ children }) => {

  const userInit = {myUser: ""}
  const { authUser }  = useContext(AuthContext) || userInit
  const { user } = authUser || userInit


  return (
    // <FirebaseContext.Provider value={new Firebase()}>
    <main className="app-main">
      {!user && <Nav />}
      {user && <Sidenav />}
      <div className="children">
        {children}
      </div>
      {/* <Footer />  */}
    </main>    
    
    // </FirebaseContext.Provider> 
  )
}

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppLayout
