import React, { useContext, forwardRef } from 'react';
import {Formik, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import { FirebaseContext } from '../../firebase'
import { StyledForm, StyledButton } from '../../UI/elements'
import Input from '../../UI/Form/input'
import {navigate, Link} from 'gatsby'
import { AuthContext } from '../../firebase'


const LoginSchema = Yup.object().shape({
  firstname: Yup.string().required('Veuillez entrer votre prenom'),
  lastname: Yup.string().required('Veuillez entrez votre nom'),
  terms: Yup.boolean().required("Vous devez accepter les termes et conditions").oneOf([true], "Vous devez accepter les termes et conditions"),
  email: Yup.string().email('Email invalide').required('Veuillez entrer votre email'),
  password: Yup.string().required('Veuillez entrer votre mot de passe'),
})


const Register = forwardRef((props, ref) => {
  const Firebase = useContext(FirebaseContext)
  const { setAuthUser } = useContext(AuthContext)

  const createUser = async (values) => {
    const {firstname, lastname, email, password} = values
    const response = await Firebase.register(firstname, lastname, email, password)
    console.log('response :', response);
    setAuthUser({user: response})
    localStorage.setItem('user', JSON.stringify(response))
    navigate("/app/dashboard")
  }

  return (
    <div>
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          password: '',
          terms: false
        }}
        validationSchema={LoginSchema}
        onSubmit={(values, actions) =>{
          // values.preventDefault()
          createUser(values)
          actions.resetForm()
        }}
      >
        {({isSubmitting, isValid, handleReset}) => (
          <StyledForm ref={ref}>
            <Field
              type='text'
              name='firstname'
              placeholder='Votre prenom ...'
              component={Input}
            />
            <Field
              type='text'
              name='lastname'
              placeholder='Votre nom ...'
              component={Input}
            />
            {/* <ErrorMessage name='name' /> */}
            <Field
              type='email'
              name='email'
              placeholder='Votre email ...'
              component={Input}
            />
            {/* <ErrorMessage name='email' /> */}
            <Field
              type='password'
              name='password'
              placeholder='Votre password ...'
              component={Input}
            />
            <label className="register-label">
              <Field type="checkbox" name="terms" />J'accepte les <Link to="/terms" className="term-link" component={Input}>termes et conditions</Link>
              <div className="errormessage">
                <ErrorMessage name="terms"/>
              </div>
            </label>
            {props.close && handleReset}
            <StyledButton className="color-0 color-1-text" type='submit'>Envoyer</StyledButton>
          </StyledForm>           
        )}
      </Formik>
    
    </div>
  );
});

export default Register;